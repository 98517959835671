import React, { StrictMode, lazy, Suspense } from 'react';
import ReactDOM from "react-dom/client";
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { Provider } from 'react-redux';
import thunk from 'redux-thunk';
import reducer from './store/reducers/auth';
import ErrorBoundary from './views/pages/systemSupport/ErrorBoundary.jsx';
import { PulseDivLoader } from './components/Loading/LoadingOverlay.js';

import { createStore, compose, applyMiddleware } from 'redux';
import './assets/css/nucleo-icons.css';
import './assets/css/animo-icons.css';
import './assets/css/animo.css';
import './assets/css/section-washup.css';
import './assets/css/data-errors.css';
import './assets/scss/animo.scss';
import "./assets/scss/animo.scss?v=1.0.0";
// import 'react-notification-alert/dist/animate.css';
const composeEnhances = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(reducer, composeEnhances(
  applyMiddleware(thunk)
));
const hist = createBrowserHistory();

const AuthLayout = lazy(() => import('./layouts/Auth/Auth.jsx'));
const AdminLayout = lazy(() => import('./layouts/Admin/Admin.jsx'));

const WebRoutes = () => {
  return (
    <Routes>
      <Route path="/auth/*" element={<AuthLayout />} >
      </Route>
      <Route path="/admin/*" element={<AdminLayout />} >
      </Route>
      <Route
        path="*"
        element={<Navigate replace to="/auth/login" />}
      />
      <Route
        path="/auth"
        element={<Navigate replace to="/auth/login" />}
      />
    </Routes >
  );
}
const loader = async () => {
  const user = await getUser();
  if (!user) {
    return redirect("/auth");
  }
  return null;
};
const App = () => (

  <Provider store={store}>
    <Router navigator={hist} history={hist}>
      <Suspense fallback={<PulseDivLoader />}>
        < ErrorBoundary >
          <WebRoutes />
        </ErrorBoundary >
        {/* <Route path="/" render={() => <Navigate to="/auth/login" />} /> */}
        {/* <Navigate from="" to="/auth/login" /> */}
      </Suspense>
    </Router >
  </Provider >
);
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<App />
)
