import React, { useEffect, useState, useRef } from 'react';
import './styles.css';
import { PulseLoader } from 'react-spinners';
const loadingImage = require("../../assets/img/loading-chip.svg")

export function onPrintAPI({ mainRef, firstPageRef, lastPageRef, title }) {
  const printIframe = window.frames["print_frame"];

  printIframe.document.title = title;
  const linkedStylesHTML = Array.from(document.head.getElementsByTagName("link"))
    .filter((link) => link.rel === 'stylesheet')
    .map((link) => link.outerHTML)
    .reduce((acc, next) => (acc += next));;

  const stylesHTML = Array.from(document.head.getElementsByTagName("style"))
    .map((style) => style.outerHTML)
    .reduce((acc, next) => (acc += next));

  printIframe.document.head.innerHTML = stylesHTML + linkedStylesHTML;
  //   printIframe.document.head.innerHTML = document.head.innerHTML;
  const getCurrentRefInnerHtml = (ref) => (ref ? ref.current.innerHTML : "");
  const replaceCanvasWithImg = (ref) => {
    const cloneRefEl = ref.current.cloneNode(true);
    const childNodes = Array.from(ref.current.children);
    const cloneChildNodes = Array.from(cloneRefEl.children);

    cloneChildNodes.forEach((el, idx) => {
      const cloneCanvasEl = Array.from(el.getElementsByTagName("canvas"));
      const origCanvasEl = Array.from(
        childNodes[idx].getElementsByTagName("canvas")
      );
      cloneCanvasEl.forEach((c, canIdx) => {
        const imgTag = document.createElement("img");
        const canvasToImg = origCanvasEl[canIdx].toDataURL();
        imgTag.src = canvasToImg;
        imgTag.width = c.width;
        imgTag.height = c.height;
        imgTag.style.cssText = c.style.cssText;
        c.replaceWith(imgTag);
      });
    });
    return cloneRefEl;
  };
  const mainRefCloneEl = replaceCanvasWithImg(mainRef);

  printIframe.document.body.innerHTML =
    getCurrentRefInnerHtml(firstPageRef) +
    mainRefCloneEl.innerHTML +
    getCurrentRefInnerHtml(lastPageRef);

  const allImgTags = Array.from(
    printIframe.document.getElementsByTagName("img")
  );
  let count = 0;
  const trackAllImgs = function (totalCount, iframe) {
    return function (e) {
      count += 1;
      if (count === totalCount) {
        iframe.window.print();
      }
    };
  };

  allImgTags.forEach((img) => {
    img.addEventListener("load", trackAllImgs(allImgTags.length, printIframe));
  });
  printIframe.window.focus();
}

export function PrintFrame() {
  return (
    <iframe
      id="printing-frame"
      name="print_frame"
      title="Print Preview"
      src="about:blank"
      style={{ display: "none", width: '100vw' }}
    />
  );
}

export function Loader(props) {
  return (
    <></>
    // <div className='loaderMask'>
    //   <div className="lds-ellipsis">
    //     <div></div>
    //     <div></div>
    //     <div></div>
    //     <div></div>
    //   </div>
    // </div>
  )
}
function DoNothing() { }
export function DivLoader(props) {
  return (
    <div className='singleloaderMask'>
      <div className="div-chip-loader">
        <object style={{ height: props && props.loadHeight ? props.loadHeight : "50%" }} size={20} type="image/svg+xml" data={loadingImage}>svg-animation</object>
      </div>
    </div>
  )
}
export function PulseDivLoader(props) {
  const [isVisible, setIsVisible] = useState(true);
  return (
    <div className='singleloaderMask' style={{ display: isVisible ? "" : "none" }}>
      <div className="CloseBtn" onClick={() => setIsVisible(!isVisible)}>
        <i className="fa fa-times" aria-hidden="true"></i>
      </div>
      <div className="div-chip-loader">
        <PulseLoader size={15} margin={'8px'} color={"#323232"} loading={true} />
      </div>
    </div>
  )
}
export function WelcomeLoader(props) {
  return (
    <div className="div-chip-loader">
      <PulseLoader size={15} margin={'8px'} color={"#323232"} loading={true} />
      {/* <object style={{ height: "40%" }} size={20} type="image/svg+xml" data={loadingImage}>svg-animation</object> */}

    </div >
  )
}
// export default { PrintFrame, onPrintAPI, Loader, DivLoader, WelcomeLoader };
